<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Alias"
                  v-model="alias"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.alias"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId) ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.img"
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.img" style="color: red;">{{ this.errors.img[0] }}</div>
                  <va-button @click="uploadImage(img[0])">Завантажити</va-button>
                </va-card>
                <div style="margin-bottom: 15px;">
                  <span>Content *</span>
                  <ckeditor v-model="content" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <span v-if="errors.content" style="color: red;">{{ this.errors.content[0] }}</span>
                <div style="margin-bottom: 15px;">
                  <span>Intro *</span>
                  <ckeditor v-model="intro" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <span v-if="errors.intro" style="color: red;">{{ this.errors.intro[0] }}</span>
                <va-date-picker
                  label="Published at *"
                  :config="{enableTime: true, locale: {firstDayOfWeek: 1}, dateFormat: 'd.m.Y H:i:S'}"
                  v-model="published_at"
                  weekDays
                />
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Title"
                  v-model="seoTitle"
                />
                <va-input
                  label="Description"
                  v-model="seoDescription"
                />
                <va-input
                  label="Keywords"
                  v-model="seoKeywords"
                />
                <div style="margin-bottom: 15px;">
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-card class="mb-3" title="Image">
                  <div v-if="seoImageExisting">
                    <a style="display: block;" target="_blank" :href="seoImageExisting"><img style="max-width: 400px;" :src="seoImageExisting"></a>
                    <div><va-button small color="danger" @click="seoImageExisting = deleteImage(seoImageId) ? '' : seoImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(seoImage[0])">Завантажити</va-button>
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      img: [],
      imgId: '',
      imgExisting: '',
      imgNew: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      content: '',
      intro: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        autoParagraph: false,
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_MS_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    uploadImage (file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.imgNew = response.data.patch
          this.imgId = response.data.id
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/media/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        label: this.label,
        alias: this.alias,
        published_at: this.published_at,
        content: this.content,
        intro: this.intro,
        published: !!this.published,
        sign: this.sign ? this.sign : null,
        img: this.imgNew,
        ceo_title: this.seoTitle,
        ceo_desc: this.seoDescription,
        ceo_keywords: this.seoKeywords,
        ceo_text: this.seoText,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/news/${this.$attrs.id}`, data)
          .then(response => {
            this.$router.push({ name: 'ms-capital-news' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/news`, data)
          .then(response => {
            this.$router.push({ name: 'ms-capital-news' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/news/${this.$attrs.id}`)
          .then(response => {
            console.log(response.data)
            this.alias = response.data.data.alias
            this.label = response.data.data.label
            this.content = response.data.data.content
            this.intro = response.data.data.intro
            this.imgNew = response.data.data.img
            this.imgExisting = process.env.VUE_APP_MS_URL + response.data.data.img
            this.published = !!response.data.data.published
            this.seoTitle = response.data.data.ceo_title
            this.seoDescription = response.data.data.ceo_desc
            this.seoText = response.data.data.ceo_text
            this.seoKeywords = response.data.data.ceo_keywords
            this.seoImage = response.data.data.ceo_image

            const date = new Date(response.data.data.published_at * 1000)
            this.published_at = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
